import { useAppContext } from "shared/useAppContext";
import Prismic from "prismic-javascript";

/* Preview cannot use hooks but can be async, so fetching pages */
export const previewLinkResolver = async ({ type, uid }) => {
  const { results } = await getDocs({ type: "page" });
  const linkResolver = createLinkResolverWithPages(results);
  return linkResolver({ type, uid });
};

export const linkResolver = ({ type, uid }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pages } = useAppContext();
  const linkResolver = createLinkResolverWithPages(pages);
  return linkResolver({ type, uid });
};

const createLinkResolverWithPages = (pages) => {
  return ({ type, uid }) => {
    if (type === "agenda_overview") return `/agenda`;
    if (type === "agenda_detail") return `/agenda/${uid}`;
    if (type === "blog_overview") return `/blog`;
    if (type === "blog_detail") return `/blog/${uid}`;
    if (type === "event") return `/events/${uid}`;
    if (type === "training_overview") return `/trainingen`;
    if (type === "training_detail") return `/trainingen/${uid}`;
    if (type === "training_reviews") return `/trainingen/${uid}/reviews`;
    if (type === "training_reviews_form")
      return `/trainingen/${uid}/reviews/formulier`;
    if (type === "coach_overview") return `/coaches`;
    if (type === "coach_detail") return `/coaches/${uid}`;
    if (type === "course_detail") return `/opleidingen/${uid}`;
    if (type === "course_reviews") return `/opleidingen/${uid}/reviews`;
    if (type === "course_reviews_form")
      return `/opleidingen/${uid}/reviews/formulier`;
    if (type === "course_studyguide")
      return `/opleidingen/${uid}/studiegids-aanvragen`;
    if (type === "course_overview") return `/opleidingen`;
    if (type === "about_us_overview") return `/over-ons`;
    if (type === "book_overview") return `/over-ons/webshop`;
    if (type === "book_detail") return `/over-ons/webshop/${uid}`;
    if (type === "team_overview") return `/over-ons/team`;
    if (type === "team_detail") return `/over-ons/team/${uid}`;
    if (type === "teacher_overview") return `/over-ons/docenten`;
    if (type === "teacher_detail") return `/over-ons/docenten/${uid}`;
    if (type === "homepage") return `/`;
    if (type === "contact") return `/contact`;
    if (type === "page") {
      const page = pages.find((page) => page.uid === uid);
      return createPath(page, pages);
    }

    return `/${uid}`;
  };
};

export const hrefResolver = ({ type }) => {
  if (type === "agenda_overview") return `/agenda`;
  if (type === "agenda_detail") return `/agenda/[uid]`;
  if (type === "blog_overview") return `/blog`;
  if (type === "blog_detail") return `/blog/[uid]`;
  if (type === "event") return `/events/[uid]`;
  if (type === "training_overview") return `/trainingen`;
  if (type === "training_detail") return `/trainingen/[uid]`;
  if (type === "training_reviews") return `/trainingen/[uid]/reviews`;
  if (type === "training_reviews_form")
    return `/trainingen/[uid]/reviews/formulier`;
  if (type === "coach_overview") return `/coaches`;
  if (type === "coach_detail") return `/coaches/[uid]`;
  if (type === "course_detail") return `/opleidingen/[uid]`;
  if (type === "course_reviews") return `/opleidingen/[uid]/reviews`;
  if (type === "course_reviews_form")
    return `/opleidingen/[uid]/reviews/formulier`;
  if (type === "course_studyguide")
    return `/opleidingen/[uid]/studiegids-aanvragen`;
  if (type === "course_overview") return `/opleidingen`;
  if (type === "about_us_overview") return `/over-ons`;
  if (type === "book_overview") return `/over-ons/webshop`;
  if (type === "book_detail") return `/over-ons/webshop/[uid]`;
  if (type === "team_overview") return `/over-ons/team`;
  if (type === "team_detail") return `/over-ons/team/[uid]`;
  if (type === "teacher_overview") return `/over-ons/docenten`;
  if (type === "teacher_detail") return `/over-ons/docenten/[uid]`;
  if (type === "homepage") return `/`;
  if (type === "contact") return `/contact`;
  if (type === "page") return `/[...slug]`;

  return `/[...slug]`;
};

export async function getDocs({
  options = {},
  pageSize = 100,
  previewData = undefined,
  type = "page",
  where = {},
}) {
  const predicates = [Prismic.Predicates.at("document.type", type)];

  const wheres = Object.entries(where);

  if (wheres.length > 0) {
    wheres.forEach(([key, value]) =>
      predicates.push(Prismic.Predicates.at(key, value))
    );
  }

  const client = await Prismic.client(process.env.NEXT_PUBLIC_PRISMIC_ENDPOINT);
  const api = await client.getApi();

  const previewRef = previewData?.token;
  const masterRef = api.refs.find((ref) => ref.isMasterRef).ref;
  const ref = previewRef || masterRef;

  const docs = await client.query(predicates, { ...options, pageSize, ref }); // If more than 100 pages, needs pagination
  return docs || null;
}

export async function getDocByUID({
  options = {},
  previewData,
  type = "page",
  uid,
}) {
  const client = await Prismic.client(process.env.NEXT_PUBLIC_PRISMIC_ENDPOINT);
  const api = await client.getApi();

  const previewRef = previewData?.token;
  const masterRef = api.refs.find((ref) => ref.isMasterRef).ref;
  const ref = previewRef || masterRef;

  const doc = await client.getByUID(type, uid, { ...options, ref });
  return doc || null;
}

export async function getSingle({ previewData, type, options = {} }) {
  const client = await Prismic.client(process.env.NEXT_PUBLIC_PRISMIC_ENDPOINT);
  const api = await client.getApi();

  const previewRef = previewData?.token;
  const masterRef = api.refs.find((ref) => ref.isMasterRef).ref;
  const ref = previewRef || masterRef;
  const doc = await client.getSingle(type, { ref, ...options });
  return doc || null;
}

export const createPath = (currentPage, pages) => {
  let path = [];
  path.unshift(currentPage.uid);
  pushParent(currentPage);

  function pushParent(currentPage) {
    const parentUid = currentPage?.data?.parent?.uid;

    if (!parentUid) {
      return;
    }

    path.unshift(parentUid);
    const parent = pages.find(({ uid }) => uid === parentUid);
    pushParent(parent);
  }

  return `/${path.join("/")}`;
};
